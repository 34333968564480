import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default ({ config, service }) => {
	const THEME_LIGHT = "light"
	const THEME_DARK = "dark"

	const VIEW_FORM = "Form"
	const VIEW_CATALOG = "Catalog"
	const VIEW_DETAIL = "Detail"
	const VIEW_PROGRAM = "Program"
	const VIEW_FAVORITES = "Favorites"
	const VIEW_MAP = "Map"
	const VIEW_NOT_FOUND = "NotFound"
	const VIEW_TEST = "Test"

	const CITY_ID_MOSCOW = 1

	return new Vuex.Store({
		state: {
			user: {},
			isReady: false,
			isFetching: false,
			externalUrl: null,
			theme: THEME_LIGHT,
			view: VIEW_FORM,
			prevView: null,
			currentChunk: 0,
			totalChunks: 0,
			apartments: [],
			favorites: [],
			selectedIndex: 0,
			openedApartment: null,
			selectedProgram: null,
			debugData: [],
			costRange: {
				min: 0,
				max: 0
			},
			cities: [],
			filter: {
				cost: "",
				city: CITY_ID_MOSCOW
			},
			viewport: {
				width: null,
				height: null
			}
		},

		getters: {
			$config: () => config,
			$service: () => service,
			$console: () => service.console,
			$userService: () => service.user,
			$apiService: () => service.api,
			$analyticService: () => service.analytics,
			$tgService: () => service.tg,

			user: ({ user }) => user,
			userId: ({}, { user }) => user.id,

			isReady: ({ isReady }) => isReady,
			isDebug: ({}, { $config }) => $config.debug,
			isLightTheme: ({ theme }) => theme === THEME_LIGHT,
			isDarkTheme: ({ theme }) => theme === THEME_DARK,
			hasAnalytics: ({}, { $analyticService }) => !!$analyticService,
			debugData: ({ debugData }) => debugData,

			hasHeader: ({}, { isCatalog, isDetail }) => isCatalog || isDetail,
			hasFooter: ({}, { isDebug, isForm, isTest }) => isDebug && (isForm || isTest),

			isForm: ({ view }) => view === VIEW_FORM,
			isCatalog: ({ view }) => view === VIEW_CATALOG,
			isDetail: ({ view }) => view === VIEW_DETAIL,
			isFavorites: ({ view }) => view === VIEW_FAVORITES,
			isMap: ({ view }) => view === VIEW_MAP,
			isTest: ({ view }, { isDebug }) => isDebug && view === VIEW_TEST,

			// history: ({ history }) => history,
			externalUrl: ({ externalUrl }) => externalUrl,
			theme: ({ theme }) => theme,
			view: ({ view }) => view,
			prevView: ({ prevView }) => prevView,
			filter: ({ filter }) => filter,
			cost: ({ filter }) => filter.cost || "",
			city: ({ filter }) => filter.city || null,
			cityName: ({ filter, cities }) => cities.find(item => item.id === filter.city)?.name,
			cities: ({ cities }) => {
				return [
					cities.find(({ name }) => name === "Москва и область"),
					cities.find(({ name }) => name === "Санкт-Петербург"),
					...cities
						.map(({ id, name }) => {
							if (name !== "Москва и область" && name !== "Санкт-Петербург") {
								return { id, name }
							}
						})
						.sort((a, b) => a.name.localeCompare(b.name))
				].filter(item => !!item)
			},
			costRange: ({ costRange }) => costRange,
			minCost: ({ costRange }) => Math.ceil(costRange.min / 1000) * 1000,
			maxCost: ({ costRange }) => Math.floor(costRange.max / 1000) * 1000,

			hapticFeedback: ({}, { $tgService }) => $tgService.app.HapticFeedback,
			backButton: ({}, { $tgService }) => $tgService.app.BackButton,
			backRoutesMap: () => {
				return {
					[VIEW_CATALOG]: VIEW_FORM,
					[VIEW_DETAIL]: VIEW_CATALOG,
					[VIEW_FAVORITES]: VIEW_CATALOG,
					[VIEW_MAP]: VIEW_CATALOG
				}
			},
			backRoute: ({}, { backRoutesMap, prevView, view }) => prevView || backRoutesMap[view] || VIEW_FORM,

			currentChunk: ({ currentChunk }) => currentChunk,
			totalChunks: ({ totalChunks }) => totalChunks,
			apartments: ({ apartments }) => apartments,

			openedApartment: ({ openedApartment }) => openedApartment,
			openedApartmentId: ({}, { openedApartment }) => openedApartment.external_id || null,

			selectedIndex: ({ selectedIndex }) => selectedIndex,
			selectedApartment: ({}, { apartments, selectedIndex }) => apartments[selectedIndex] || null,
			getSelectedProgram: ({}, { selectedProgram }) => selectedProgram,
			selectedApartmentId: ({}, { selectedApartment }) => selectedApartment.external_id || null,

			nextIndex: ({}, { selectedIndex }) => selectedIndex + 1,
			nextApartment: ({}, { apartments, nextIndex }) => apartments[nextIndex] || apartments[0] || null,

			nextFetchingIndex: ({}, { apartments }) => apartments.length - 10,

			favorites: ({ favorites }) => favorites,
			favoriteIds: ({}, { favorites }) => favorites.map(favorite => favorite.id),
			favoritesNum: ({}, { favorites }) => {
				return favorites.reduce((accum, item) => {
					item.items.length && accum.push(...item.items)
					return accum
				}, []).length;
			},

			hasFetched: ({}, { apartments }) => !!apartments.length,
			canNextFetch: ({}, { selectedIndex, nextFetchingIndex }) => selectedIndex >= nextFetchingIndex,

			isFetching: ({ isFetching }) => isFetching,
			isFirstFetching: ({}, { hasFetched, isFetching }) => !hasFetched && isFetching,
			isNextFetching: ({}, { hasFetched, isFetching }) => hasFetched && isFetching
		},

		mutations: {
			setUser: (state, user) => {
				state.user = user
			},
			setReady: (state, status) => {
				state.isReady = status
			},
			setFetching: (state, status) => {
				state.isFetching = status
			},
			setExternalUrl: (state, url) => {
				state.externalUrl = url
			},
			setTheme: (state, theme) => {
				state.theme = theme
			},
			setView: (state, view) => {
				state.view = view
			},
			setPrevView: (state, view) => {
				state.prevView = view
			},
			setCurrentChunk: (state, chunk) => {
				state.currentChunk = chunk
			},
			setTotalChunks: (state, totalChunks) => {
				state.totalChunks = totalChunks
			},
			setFilter: (state, filter) => {
				state.filter = filter
			},
			setCostRange: (state, costRange) => {
				state.costRange = costRange
			},
			setCities: (state, cities) => {
				state.cities = cities
			},
			setSelectedIndex: (state, index) => {
				state.selectedIndex = index
			},
			setApartments: (state, apartments) => {
				state.apartments = apartments
			},
			setSelectedProgram: (state, program) => {
				state.selectedProgram = program
			},
			setOpenedApartment: (state, apartment) => {
				state.openedApartment = apartment
			},
			setFavorites: (state, favorites) => {
				state.favorites = favorites || [];
			},
			setDebugData: (state, data) => {
				state.debugData = data
			}
		},

		actions: {
			init: async ({ getters, commit, dispatch }) => {
				const { $userService, $tgService, backButton } = getters

				backButton.onClick(() => dispatch("toBack"))
				$tgService.app.onEvent("themeChanged", () => commit("setTheme", $tgService.app.colorScheme))

				dispatch("initListeners")
				dispatch("assignThemeParams")
				dispatch("fetchFavorites")

				commit("setUser", $userService.getUser())
				commit("setTheme", $tgService.app.colorScheme)

				await dispatch("fetchCities")

				setTimeout(() => {
					const preloader = document.querySelector(".preloader")

					preloader && preloader.remove()

					commit("setReady", true)
				}, 1000)
			},
			pushEvent: async ({ getters, commit, dispatch }, { event, data }) => {
				const { user, $apiService, $analyticService } = getters

				data = data || {}
				data.userId = user.id

				await $apiService.pushEvent(event, data)
				$analyticService.push(event, data)

				console.info("Analytics::pushEvent", { event, data })
			},
			toRoute: ({ getters, commit, dispatch }, nextView, isEnabledAnalytic = true) => {
				const { backButton, view } = getters

				commit("setPrevView", view)
				commit("setView", nextView)

				if (isEnabledAnalytic) {
					dispatch("pushEvent", { event: "ROUTE_TO", data: { from: view, to: nextView } })
				}

				if (nextView === VIEW_FORM) {
					backButton.hide()

					dispatch("resetApartaments")
				} else if (!backButton.isVisible) {
					backButton.show()
				}

				dispatch("resetThemeParams")
			},
			toLink({ getters, commit }, url) {
				// commit("setExternalUrl", url)

				getters.$tgService.app.openLink(url)
				dispatch("pushEvent", { event: "ROUTE_TO_LINK", data: { url } })

				// getters.$tgService.app.openTelegramLink(url)

				// const link = document.createElement("a")

				// link.setAttribute("href", url)
				// link.click()
			},
			toBack: ({ getters, commit, dispatch }) => {
				if (getters.externalUrl) {
					commit("setExternalUrl", null)
				} else {
					const { view, prevView } = getters
					dispatch("pushEvent", { event: "ROUTE_TO_BACK", data: { from: view, to: getters.backRoute } })
					dispatch("toRoute", getters.backRoute, false)
					commit("setPrevView", null)
				}
			},
			changeFilter: ({ getters, commit }, params) => {
				commit("setFilter", { ...getters.filter, ...params })
			},
			selectCity: ({ dispatch, getters }, { cityId, withoutAnalytic = false }) => {
				dispatch("changeFilter", { city: cityId })
				dispatch("fetchCostRange")

				const { city, cityName } = getters
				const data = {
					cityId: city || 0,
					cityName: cityName || "Город N"
				}

				!withoutAnalytic && dispatch("pushEvent", { event: "select-city", data })
			},
			changeCost: ({ dispatch }, cost) => {
				let result = cost;
				result = result.replace(/[^0-9]+/g, '');

				dispatch("changeFilter", { cost: result });
			},
			selectApartment: ({ getters, commit, dispatch }, index) => {
				commit("setSelectedIndex", index)

				if (getters.canNextFetch) {
					dispatch("fetchApartments")
				}
			},
			openApartment: ({ commit }, apartment) => {
				commit("setOpenedApartment", apartment)
			},
			updateFavorite: async ({ getters, dispatch }, apartment) => {
				getters.hapticFeedback.impactOccurred("medium")
				getters.hapticFeedback.notificationOccurred("success")
				await getters.$apiService.updateFavorite(apartment.id, apartment.entity_type)

				dispatch("fetchFavorites")
			},
			fetchFavorites: async ({ getters, commit }) => {
				// const localFavorites = getters.$userService.favorites

				// if (localFavorites.length) {
				// 	const localFavoriteIds = localFavorites.map(localFavorite => localFavorite.id)

				// 	await getters.$apiService.importFavorite(localFavoriteIds)
				// 	getters.$userService.unsetFavorites()
				// }

				const favorites = await getters.$apiService.fetchFavorites();

				commit("setFavorites", favorites)
			},
			fetchCostRange: async ({ getters, commit }) => {
				const costRange = await getters.$apiService.fetchCostRange([getters.city])

				commit("setCostRange", costRange)
			},
			fetchCities: async ({ getters, commit, dispatch }) => {
				const cities = await getters.$apiService.fetchCities()
				const cityId = cities[0] ? cities[0].id : null

				commit("setCities", cities)

				cityId && dispatch("selectCity", { cityId, withoutAnalytic: true })
			},
			fetchApartments: async ({ getters, commit }) => {
				const { $apiService, cost, city, currentChunk, totalChunks, apartments, isFetching } = getters
				const page = currentChunk + 1
				const canFetch = totalChunks ? page <= totalChunks : true

				const cityIds = [city]

				if (isFetching || !canFetch) {
					return
				}

				commit("setFetching", true)

				const { page_count, items } = await $apiService.fetchApartments({ monthFee: cost, cityIds })

				commit("setFetching", false)
				commit("setCurrentChunk", page)
				commit("setTotalChunks", page_count)
				commit("setApartments", [...apartments, ...items])
			},
			resetApartaments: ({ commit }) => {
				commit("setCurrentChunk", 0)
				commit("setTotalChunks", 0)
				commit("setApartments", [])
			},

			// Routes
			toForm: ({ dispatch }) => {
				dispatch("resetApartaments")
				dispatch("toRoute", VIEW_FORM)
			},
			toFavorites: ({ dispatch }) => {
				dispatch("toRoute", VIEW_FAVORITES)
			},
			toMap: ({ dispatch }) => dispatch("toRoute", VIEW_MAP),
			toTest: ({ dispatch }) => dispatch("toRoute", VIEW_TEST),
			toCatalog: ({ dispatch }) => {
				dispatch("selectApartment", 0)
				dispatch("toRoute", VIEW_CATALOG)
			},
			toDetail: ({ getters, dispatch }, apartment) => {
				if (!apartment) {
					return
				}

				dispatch("openApartment", apartment)
				dispatch("toRoute", VIEW_DETAIL)

				getters.$analyticService.push("view-detail-card", {
					objectId: getters.openedApartmentId
				})
			},
			toProgram: ({ getters, dispatch, commit }, apartment) => {
				if (!apartment) {
					return
				}

				// commit("setSelectedProgram", null)
				dispatch("openApartment", apartment)
				dispatch("toRoute", VIEW_PROGRAM)
			},
			addSelectedProgram: ({ getters, dispatch, commit, state }, program) => {
				commit("setSelectedProgram", program)
			},

			toNext: ({ getters, dispatch }) => {
				if (!getters.nextApartment) {
					return
				}

				dispatch("openApartment", getters.nextApartment)
				dispatch("toRoute", VIEW_DETAIL)
				dispatch("selectApartment", getters.nextIndex)
			},
			toggleTheme: ({ getters, commit }) => {
				commit("setTheme", getters.isLightTheme ? THEME_DARK : THEME_LIGHT)
			},
			assignThemeParams({ getters }) {
				const root = document.documentElement.style
				const width = window.visualViewport.width
				const height = window.visualViewport.height

				root.setProperty("--viewport-width", `${width}px`)
				root.setProperty("--viewport-height", `${height}px`)

				// getters.$console.log("assignThemeParams", { width, height })
			},
			resetThemeParams() {
				const root = document.documentElement.style

				root.setProperty("--viewport-width", "100%")
				root.setProperty("--viewport-height", "100%")
			},
			initListeners({ dispatch }) {
				// window.addEventListener("scroll", event => dispatch("onScroll", event))
				// window.visualViewport.addEventListener("resize", event => dispatch("onResize", event))
			},
			log({ getters, commit }, data) {
				commit("setDebugData", [...getters.debugData, data])
			}
		}
	})
}
